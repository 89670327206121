import React, { useEffect, useState } from 'react';
import axios from 'axios';

function formatDate(isoDateString) {
    const date = new Date(isoDateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // 月は0-indexedなので+1する
    const day = date.getDate();
    return `${year}年${month}月${day}日`;
}

function LatestArticle() {
    const [latestArticle, setLatestArticle] = useState(null);

    useEffect(() => {
        const fetchLatestArticle = async () => {
            try {
                const result = await axios.get(process.env.REACT_APP_API_ENDPOINT, {
                    headers: { 'X-API-KEY': process.env.REACT_APP_API_KEY }
                });

                // 最新の記事を取得し、日付をフォーマットする
                if (result.data.contents.length > 0) {
                    const latest = result.data.contents[0];
                    latest.date = formatDate(latest.date);

                    // コンテンツが指定された文字数以上の場合、...に置き換える
                    const maxContentLength = 45; // 最大文字数
                    if (latest.content.length > maxContentLength) {
                        latest.content = latest.content.substring(0, maxContentLength) + ' ...';
                    }

                    setLatestArticle(latest);
                } else {
                    throw new Error('No articles found');
                }
            } catch (error) {
                console.error('Error fetching latest article:', error);
            }
        };

        fetchLatestArticle();
    }, []);

    return (
        <div className="latest-article">
            {latestArticle && (
                <div className="news-note note-box">
                    <h1>{latestArticle.title}</h1>
                    <p className='news-date'>{latestArticle.date}</p>
                    <div className='news-content' dangerouslySetInnerHTML={{ __html: latestArticle.content }} />
                </div>
            )}
        </div>
    );
}

export default LatestArticle;
