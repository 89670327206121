import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import AnimationTrigger from './AnimationTrigger';
import NewTab_svg from '../img/svg/arrow-up-right-from-square-solid.svg';

function Rule() {
    return (
        <div className='comp'>


            <Helmet>
                <script type="application/ld+json">
                    {`
              {
                "@context": "http://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "item": {
                      "@id": "https://woodryserver.pages.dev",
                      "name": "WoodryServer"
                    }
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "item": {
                      "@id": "https://woodryserver.pages.dev/rule",
                      "name": "サイトポリシー"
                    }
                  }
                ]
              }
            `}
                </script>
                <title>サイトポリシー | WoodryServer</title>
                <meta name="description" content="Woodryさんが主催する参加型マルチサーバーのファンメイドサイトです。このページではサイトポリシーが表示されます。" />
            </Helmet>


            {/* パンくずリスト */}
            <section className='page-crumb'>
                <nav>
                    <ol className="breadcrumb" itemscope itemtype="https://schema.org/BreadcrumbList">
                        <li itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
                            <Link to="/" itemprop="item">
                                <span itemprop="name">WoodryServer</span>
                            </Link>
                            <meta itemprop="position" content="1" />
                        </li>
                        <li itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
                            <span itemprop="name">サイトポリシー</span>
                            <meta itemprop="position" content="2" />
                        </li>
                    </ol>
                </nav>
            </section>

            <AnimationTrigger>
                <section id="rules">
                    <div className="rules">
                        <h1 id='site'>サイトポリシー</h1>
                        <div className="rules-text">
                            <p>当サイトでの個人情報取扱についてや注意事項などを記述していますのでぜひご一読ください。</p>

                            {/* 本文1 */}
                            <h2 id="rule1">1.当サイトについて</h2>
                            <p>開催が待ちきれなくて作ったファンメイドのサイトです。</p>
                            <p>当サイトは<a href="https://twitter.com/woodry_" target="_blank" rel="noreferrer">
                                <img src={NewTab_svg} alt="新規タブSVG" className='newTab' />
                                Woodryさん</a>(公式)のサイトではありません、</p>
                            <p>そのため一部表記に誤りがある場合があります。あらかじめご了承ください。</p>

                            {/* 本文2 */}
                            <h2 id="rul2">2.個人情報取扱について</h2>
                            <p>当サイトではcookieの収集をしています。使用用途は以下の通りです。</p>
                            <ul>
                                <li>cookieの使用同意確認</li>
                                <li>Googleアナリティクス</li>
                            </ul>
                            <p>上記の情報以外では使用していませんので安心してご利用ください。</p>

                            {/* 本文3 */}
                            <h2 id="rul3">3.画像使用について</h2>
                            <p>当サイトでの募集した画像の使用範囲は以下の通りです。</p>
                            <ul>
                                <li>サイト内でのサーバー紹介</li>
                            </ul>
                            <p>上記以外の使用用途では使用しません。また、どうしても納得のいかない場所(希望しない場所)に掲載された場合は<br />
                                <Link to="/contact">
                                    <img src={NewTab_svg} alt="新規タブSVG" className='newTab' />
                                    こちら</Link>
                                からお問い合わせください。
                            </p>

                            {/* 本文4 */}
                            <h2 id="rul4">4.当サイトのバグ報告について</h2>
                            <p>当サイトは<span className="page-title">word press</span>等の使用をせずフルコードのためバグが起こる可能性があります。</p>
                            <p>当サイトにてバグを発見した場合は
                                <Link to="/contact">
                                    <img src={NewTab_svg} alt="新規タブSVG" className='newTab' />
                                    こちら
                                </Link>
                                のフォームより</p>
                            <ul>
                                <li>メールアドレス</li>
                                <li>お名前</li>
                                <li>お問い合わせ内容(今回の場合は「バグ報告」)</li>
                                <li>お問い合わせ本文(どのようなバグが起こるのか/どのような行動をしたらなるのか等)</li>
                                <li>その他補足情報(任意)</li>
                            </ul>
                            <p>を送っていただけるとバグ修正を円滑にできるためご協力お願いいたします。</p>

                            {/* <最後に */}
                            <h2 id="rul-end">最後に</h2>
                            <p>本規約は予告なく変更する場合があります。ぜひ一度は読んでいただけるとありがたいです。</p>
                            <p>ぜひ一緒に、SNS等でシェアをしてウッドリィさんを布教しちゃいましょう!</p>
                        </div>
                    </div>
                </section>
            </AnimationTrigger>

        </div>
    )
}

export default Rule;