import React, { useEffect } from 'react';

const TwitterShareButton = () => {
    useEffect(() => {
        // Twitterウィジェットのスクリプトを再実行するための関数
        const loadTwitterWidgetScript = () => {
            const script = document.createElement('script');
            script.async = true;
            script.src = 'https://platform.twitter.com/widgets.js';
            script.charset = 'utf-8';
            document.body.appendChild(script);
        };

        //   初回レンダリング時にスクリプトを読み込む
        loadTwitterWidgetScript();

        //   コンポーネントがアンマウントされるときにスクリプトを削除する
        return () => {
            const scripts = Array.from(document.getElementsByTagName('script'));
            const twitterScript = scripts.find(s => s.src.includes('platform.twitter.com/widgets.js'));
            if (twitterScript) {
                document.body.removeChild(twitterScript);
            }
        };
    }, []);

    return (
        <a
            href="https://twitter.com/share?ref_src=twsrc%5Etfw"
            className="twitter-share-button"
            data-size="large"
            data-text="WoodrySeverのウェブサイトです!"
            data-via="reno_ma1n"
            data-hashtags="woodryserver,woodry,マインクラフト,Minecraft,マルチサーバー,視聴者参加型"
            data-related="@woodry_"
            data-show-count="false"
        >
            Tweet
        </a>
    );
};

export default TwitterShareButton;
