import React, { useState } from 'react';
import { HashLink } from 'react-router-hash-link';

import TwitterShareButton from './TwitterShareButton';

import QR_SharePNG from '../img/img/QR_PageShare.png';

import X_LogoIcon from '../img/svg/x-twitter.svg';
import Youtube_LogoIcon from '../img/svg/youtube.svg';
import Bluesky_LogoIcon from '../img/svg/bluesky-1.svg';
import Copy_LogoIcon from '../img/svg/copy-solid.svg';
import Page_Up from '../img/svg/page-up.svg';

function Footer() {
    const [copyMessage, setCopyMessage] = useState('サイトURLをコピー');

    const copyPageUrl = () => {
        const copyText = window.location.href;

        //  仮のtextareaを作成し、そこにリンクをセットする
        const tempTextArea = document.createElement("textarea");
        tempTextArea.value = copyText;
        document.body.appendChild(tempTextArea);

        //  テキストを選択し、コピー
        tempTextArea.select();
        document.execCommand('copy');

        //  仮のtextareaを削除
        document.body.removeChild(tempTextArea);

        //  コピー完了メッセージを表示
        setCopyMessage('コピー完了！');

        //  2秒後にメッセージを元に戻す
        setTimeout(() => {
            setCopyMessage('サイトURLをコピー');
        }, 2000);
    };

    const showConfirmation = () => {
        //  サイトダイアログを表示
        var confirmation = window.confirm("ダウンロードしますか？");

        //  ユーザーが「はい」を選択した場合
        if (confirmation) {
            //  ここに画像をダウンロードするための処理を追加
            //  例:  画像のURLを指定してダウンロード
            var imageUrl = QR_SharePNG;
            var link = document.createElement('a');
            link.href = imageUrl;
            link.download = 'WoodryServer_QR.png';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };


    return (
        <div className='footer'>
            <div className='page-up'>
                <button onClick={scrollToTop}>
                    <img src={Page_Up} alt="Page_Up" />
                </button>
            </div>
            <div className="foot-ul">
                <div>
                    <ul>
                        <p>ホーム</p>
                        <li><HashLink to="/#">トップ</HashLink></li>
                        <li><HashLink to="/#about">アバウト</HashLink></li>
                        <li><HashLink to="/#join">参加方法</HashLink></li>
                        <li><HashLink to="/#servers">過去サーバー</HashLink></li>
                        <li><HashLink to="/#home-rule">サイトポリシー</HashLink></li>
                        <li><HashLink to="/#home-contact">お問い合わせ</HashLink></li>
                        <li><HashLink to="/#home-news">ニュース</HashLink></li>
                        <li><HashLink to="/#home-sns">最新動画/ポスト</HashLink></li>
                    </ul>
                    <ul>
                        <p>ニュース</p>
                        <li><HashLink to="/news#">トップ</HashLink></li>
                    </ul>
                    <ul>
                        <p>サイトポリシー</p>
                        <li><HashLink to="/rule#">トップ</HashLink></li>
                        <li><HashLink to="/rule#rule1">当サイトについて</HashLink></li>
                        <li><HashLink to="/rule#rul2">個人情報取扱について</HashLink></li>
                        <li><HashLink to="/rule#rul3">画像使用について</HashLink></li>
                        <li><HashLink to="/rule#rul4">当サイトのバグ報告について</HashLink></li>
                    </ul>
                    <ul>
                        <p>お問い合わせ</p>
                        <li><HashLink to="/contact#">お問い合わせフォーム</HashLink></li>
                    </ul>
                </div>
                <div className="footerQR">
                    <img src={QR_SharePNG} alt="PageQR" />
                    <button onClick={showConfirmation} id="qr-share">
                        サイトをQRコードでシェア
                    </button>
                </div>
            </div>
            <div className="sns">
                <ul>
                    <h3>公式SNS</h3>
                    <li className='twitter'><a target='_blank' rel="noreferrer" href="https://twitter.com/intent/follow?original_referer=https%3A%2F%2Fbuhitter.com%2F&ref_src=twsrc%5Etfw%7Ctwcamp%5Ebuttonembed%7Ctwterm%5Efollow%7Ctwgr%5Ewoodry_&region=follow_link&screen_name=woodry_">
                        <img src={X_LogoIcon} alt="x-twitter" /><small>Twitter(X)</small>
                    </a></li>
                    <li className='youtube'><a target='_blank' rel="noreferrer" href="https://www.youtube.com/@woodry">
                        <img src={Youtube_LogoIcon} alt="youtube" /><small>Youtube</small>
                    </a></li>
                    <li className='bluesky'><a target='_blank' rel="noreferrer" href="https://bsky.app/profile/woodry.bsky.social">
                        <img src={Bluesky_LogoIcon} alt="Bluesky" /><small>Bluesky</small>
                    </a></li>
                </ul>
                <ul>
                    <h3>シェア</h3>
                    <TwitterShareButton />
                    <li className='copy-btu'>
                        <button type="button" onClick={copyPageUrl}>
                            <img src={Copy_LogoIcon} alt="" />
                            <p>{copyMessage}</p>
                        </button>
                    </li>
                </ul>
                <p className='footer-kawaiiErr'>
                    404Logo by
                    <a href="https://twitter.com/sawaratsuki1004"
                        target="_blank"
                        rel="noopener noreferrer">
                        @sawaratsuki1004
                    </a>
                </p>
            </div>
            <h4>制作・公開
                <a className='aLINK'
                    href="https://twitter.com/reno_ma1n"
                    target="_blank"
                    rel="noopener noreferrer">
                    Reno</a>.
                <br /><br />
                ©  2023 -  2024 WoodryServer. ALL RIGHTS RESERVED.
            </h4>
        </div>
    );
}

export default Footer;
