import React from 'react';

class AmpAd extends React.Component {
    render() {
        return (
            <div dangerouslySetInnerHTML={{
                __html: `
        <amp-auto-ads type="adsense"
            data-ad-client="ca-pub-1638013286657091">
        </amp-auto-ads>
      ` }} />
        );
    }
}

export default AmpAd;
