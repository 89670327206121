import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import AnimationTrigger from "../AnimationTrigger";
import cloud_svg from "../../img/svg/cloud.svg";

function formatDate(isoDateString) {
  const date = new Date(isoDateString);
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // 月は0-indexedなので+1する
  const day = date.getDate();
  return `${year}年${month}月${day}日`;
}

function ArticleDetail() {
  const [article, setArticle] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        setIsLoading(true);
        const apiEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/${id}`;
        setTimeout(async () => {
          const response = await axios.get(apiEndpoint, {
            headers: { "X-API-KEY": process.env.REACT_APP_API_KEY },
          });
          setArticle(response.data);
          setIsLoading(false);
        }, 1000);
      } catch (error) {
        console.error("記事の取得に失敗しました:", error);
        setIsLoading(false);
      }
    };

    fetchArticle();
  }, [id]);

  useEffect(() => {
    let script;

    if (!window.twttr) {
      script = document.createElement("script");
      script.src = "https://platform.twitter.com/widgets.js";
      script.async = true;
      script.onload = () => {
        if (window.twttr) {
          window.twttr.widgets.load();
        }
      };
      document.body.appendChild(script);

      // コンポーネントがアンマウントされたときにスクリプト要素を削除するクリーンアップ関数を返す
      return () => {
        if (script && script.parentNode) {
          script.parentNode.removeChild(script);
        }
      };
    } else {
      window.twttr.widgets.load();
    }
  }, []); // マウント時に一度だけ実行されることを保証

  useEffect(() => {
    if (article && typeof window.twttr !== "undefined") {
      window.twttr.widgets.load();
    }
  }, [article]);

  const formattedDate = article ? formatDate(article.date) : "";

  return (
    <div className="comp news-text-sec">
      <Helmet>
        <title>
          {article ? article.title : "記事を読み込んでいます..."} | WoodryServer
        </title>
      </Helmet>

      {/* パンくずリスト */}
      <section className="page-crumb">
        <nav>
          <ol className="breadcrumb">
            <li>
              <Link to="/">WoodryServer</Link>
            </li>
            <li>
              <Link to="/news">ニュース</Link>
            </li>
            <li>{article ? article.title : "記事を読み込んでいます..."}</li>
          </ol>
        </nav>
      </section>

      {/* ニュースローディング */}
      <AnimationTrigger>
        {isLoading && (
          <div id="NewsLoading">
            <img src={cloud_svg} alt="雲ローディング" />
            <div>
              <p>Now Loading...</p>
              <p>記事テータを取得しています</p>
            </div>
          </div>
        )}
      </AnimationTrigger>

      {/* ニュース内容 */}
      <AnimationTrigger>
        {article && (
          <div className="NewsText note-box">
            <h1>{article.title}</h1>
            <p className="news-date">{formattedDate}</p>
            <div
              className="news-p-content"
              dangerouslySetInnerHTML={{ __html: article.content }}
            />
            <div className="NewsTextAD">
              <script
                async
                src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1638013286657091"
                crossorigin="anonymous"
              ></script>
              <ins
                class="adsbygoogle"
                style={{ display: "block", textAlign: "center" }}
                data-ad-layout="in-article"
                data-ad-format="fluid"
                data-ad-client="ca-pub-1638013286657091"
                data-ad-slot="3411895964"
              ></ins>
              <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
              </script>
            </div>
          </div>
        )}
      </AnimationTrigger>

      <AnimationTrigger>
        <div className="news-look">
          <Link to="/news">← 一覧に戻る</Link>
        </div>
      </AnimationTrigger>
    </div>
  );
}

export default ArticleDetail;
