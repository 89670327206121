import React from 'react';
import { Link } from 'react-router-dom';

import AnimationTriggeer from '../AnimationTrigger';
import NewYouTube from '../HomePage/NewYouTube';
import TwitterTimeline from '../HomePage/Twitter';

import Err_png from '../../img/img/404 NotFound.png';

function E404() {
    return (
        <div className='comp'>
            <AnimationTriggeer>
                <div className="err">
                    <div className="Err-png-div">
                        <img className='Err-png' src={Err_png} alt="404IMG" />
                    </div>
                    <p>このページは閉鎖されているかURLが正しくありません。</p>
                    <p>URLを確認してみてください。</p>
                    <div className="err-home">
                        <Link to="/">ホームに戻る</Link>
                    </div>
                </div>


                <div className="LatestVideosPosts">
                    <NewYouTube />
                    <TwitterTimeline />
                </div>

            </AnimationTriggeer>
        </div>
    )
}

export default E404