import React, { useState, useEffect } from 'react';

import woodryserver_loading from '../img/gif/woodryserver_loading.gif'

const LoadingScreen = ({ onPageLoad }) => {
    const [isPageLoaded, setIsPageLoaded] = useState(false); // ページの読み込み状態を管理するステート

    useEffect(() => {
        // ページの読み込みが完了したら、isPageLoadedをtrueに設定
        setIsPageLoaded(true);
        onPageLoad(true);
    }, [onPageLoad]);

    return (
        <div className="loading-screen">
            {isPageLoaded && (
                <img src={woodryserver_loading} alt="woodryserver_loading" />
            )}
            <p>読み込み中です...</p>
        </div>
    );
};



export default LoadingScreen;
