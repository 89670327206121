import React from "react";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";

const FadeAnimation = ({ children }) => {
    const { ref, inView } = useInView({
        rootMargin: "-100px",
        triggerOnce: true,
    });

    // ユーザーエージェントをチェックしてSafariまたはスマートフォンのブラウザであるかどうかを判断
    const isSafariOrMobile = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    // Safariまたはスマートフォンのブラウザである場合、inViewの状態に関わらずchildrenをそのまま返す
    if (isSafariOrMobile) {
        return <>{children}</>;
    }

    return (
        <SFadeElem inView={inView} ref={ref}>
            {children}
        </SFadeElem>
    );
};

const SFadeElem = styled.span`
 transition: opacity 0.5s cubic-bezier(0.47, 0, 0.745, 0.715);
 opacity: ${(props) => (props.inView ? 1 : 0)};
`;

export default FadeAnimation;
