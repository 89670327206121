import React from 'react';
import NewTab_svg from '../../img/svg/arrow-up-right-from-square-solid.svg';

function Join() {
    return (
        <div id='join'>
            {/* 参加方法 */}
            <section className='join section'>
                <div className='sec-div'>
                    <h1 className="sec-title">参加方法</h1>
                    <h2>参加方法はたったの３ステップ！</h2>
                    <div className="join-youtube">
                        <div className="join-sec" id="join">
                            <div className="flow_design03">
                                <ul className="flow03">
                                    <li>
                                        <dl>
                                            <dt><span className="icon03">1</span>参加</dt>
                                            <dd>以下のリンクよりdiscordサーバーに参加し各種規約、応募方法等をご一読ください。<br />
                                                <a href="https://discord.com/invite/kRQgH32KtJ" target="_blank" className="discord-a" rel="noreferrer">
                                                    <img src={NewTab_svg} alt="新規タブSVG" className='newTab' />
                                                    discordサーバーに参加
                                                </a>
                                            </dd>
                                        </dl>
                                    </li>
                                    <li>
                                        <dl>
                                            <dt><span className="icon03">2</span>応募</dt>
                                            <dd>サーバー内のメッセージチャンネルにて募集のメッセージが送られますので<br />
                                                指示に従って応募をしてください。
                                            </dd>
                                        </dl>
                                    </li>
                                    <li>
                                        <dl>
                                            <dt><span className="icon03">3</span>当選発表</dt>
                                            <dd>サーバー内のメンションにて当選者発表します。</dd>
                                            <dd>当選すると表示される、サーバー内のメッセージチャンネルにて<br />サーバーアドレス(ポート)を確認してご参加ください。</dd>
                                        </dl>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="youtube">
                            <iframe width="560" height="315"
                                src="https://www.youtube-nocookie.com/embed/eIvbtXgsEJI?si=xm5Wl9BrRwIGNOY8&controls=0&fs=0&rel=0"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen></iframe>
                            <small>※音が出ます注意して再生してください</small>
                        </div>
                    </div>
                </div>
            </section >
        </div>
    )
}

export default Join