/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";

function Contact() {
  return (
    <div className='comp'>


      <Helmet>
        <script type="application/ld+json">
          {`
              {
                "@context": "http://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "item": {
                      "@id": "https://woodryserver.pages.dev",
                      "name": "WoodryServer"
                    }
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "item": {
                      "@id": "https://woodryserver.pages.dev/contact",
                      "name": "お問い合わせ"
                    }
                  }
                ]
              }
            `}
        </script>
        <title>お問い合わせ | WoodryServer</title>
        <meta name="description" content="このページからお問い合わせをできます。Woodryさんが主催する参加型マルチサーバーのファンメイドサイトです。" />
      </Helmet>


      {/* パンくずリスト */}
      <section className='page-crumb'>
        <nav>
          <ol className="breadcrumb">
            <li><Link to="/">WoodryServer</Link></li>
            <li>お問い合わせ</li>
          </ol>
        </nav>
      </section>


      <div className="form">
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSdd4KmnUk1xGdM9yG6v3qBbEd8aH0YwOiEN8An8aTQbB3N-UQ/viewform?embedded=true"
          width="100%"
          height="1200"
          frameBorder="0"
          marginheight="0"
          marginwidth="0"
        >
          読み込んでいます…
        </iframe>
      </div>
    </div>
  )
}

export default Contact