import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PageTop_mp4 from '../../img/mov/Header-Video.mp4';
import PaeTop_webm from '../../img/mov/Header-Video.webm';
import PageTop_png from '../../img/img/Header-Video.jpg';
import KawaiiImage from '../../img/svg/WoodryServer_KawaiiLogo.svg';

function PageTop() {
    const [isMobile, setIsMobile] = useState(false);
    const location = useLocation(); // 現在のURLを取得

    useEffect(() => {
        // デバイスの種類を判断する
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent)) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, []);

    const isKawaii = location.search.includes('?kawaii=true');

    return (
        <div id='top'>
            <div className="top-mov" >
                {isMobile ? (
                    <img src={PageTop_png} alt="WoodryServer" />
                ) : (
                    <video poster={PageTop_png}
                        playsInline
                        muted
                        autoPlay
                        loop
                        nocontrols>
                        <source src={PageTop_mp4} type="video/mp4" playsInline />
                        <source src={PaeTop_webm} type="video/webm" playsInline />
                        <p>お使いのブラウザはHTML5ビデオタグをサポートしていません。</p>
                    </video>
                )}
                {!isKawaii && (
                    <div className="top-mov-text">
                        <h1>WoodryServer</h1>
                        <h2>Let' Join!</h2>
                    </div>
                )}
                {isKawaii && (
                    <img className="top-mov-img" src={KawaiiImage} alt="Kawaii Logo" /> // Kawaiiロゴを表示
                )}
                <div className='top-mov-small'>
                    <small>※スマホでの動画再生に対応していません。</small>
                </div>
            </div>
        </div>
    )
}

export default PageTop;