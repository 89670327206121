import React from 'react';
import { Link } from 'react-router-dom';

function Contact() {
    return (
        <div className='comp'>
            <div className="contact" id='home-contact'>
                <h1 className='sec-title'>お問い合わせ</h1>
                <div className="cont-text">
                    <p>現在当サイトではGoogleFormを使用しお問い合わせを受け付けております</p>
                    <p>ご入力は以下のボタンがらお願いいたします。</p>
                </div>
                <div className="cont-btu">
                    <Link to="/contact">お問い合わせ</Link>
                </div>
            </div>
        </div>
    )
}

export default Contact