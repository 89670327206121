import React from 'react';
import { Link } from 'react-router-dom';

function Contact() {
    return (
        <div className='comp'>
            <div className="rule" id='home-rule'>
                <h1 className='sec-title'>サイトポリシー</h1>
                <div className="rule-text">
                    <p>当サイトでの個人情報取扱についてや注意事項などを記述していますのでぜひご一読ください。</p>
                    <p>開催が待ちきれなくて作ったファンメイドのサイトです。当サイトはWoodryさん...</p>
                </div>
                <div className="rule-btu">
                    <Link to="/rule">詳しく読む</Link>
                </div>
            </div>
        </div>
    )
}

export default Contact