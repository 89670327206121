import React, { useState, useEffect } from 'react';
import Modal from './Modal';

function getThumbnailUrl(videoId) {
    return `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
}

function PastServers() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState('');
    const [isAutoScrolling, setIsAutoScrolling] = useState(false);

    useEffect(() => {
        const container = document.querySelector('.PastServers');
        const images = document.querySelectorAll('.PastServers img');

        let currentIndex = 0;
        let scrollInterval;

        const scrollNextImage = () => {
            if (currentIndex === images.length) {
                currentIndex = 0;
            }
            container.scrollTo({
                left: images[currentIndex].offsetLeft - container.clientWidth / 2 + images[currentIndex].clientWidth / 2,
                behavior: 'smooth'
            });
            currentIndex++;
        };

        if (!isAutoScrolling) {
            scrollInterval = setInterval(scrollNextImage, 3000);
        }

        return () => {
            clearInterval(scrollInterval);
        };
    }, [isAutoScrolling]);

    const handleImageClick = (videoId) => {
        setSelectedVideo(videoId);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const toggleAutoScroll = () => {
        setIsAutoScrolling(!isAutoScrolling);
    };

    return (
        <div className='comp PastServersSec' id='servers'>
            <h1 className='sec-title'>過去サーバー</h1>
            <p><br className='PastServers-sp-br' />横にスクロールできます</p>
            <p>画像をクリックすると動画を再生できます</p>
            <br />
            <button onClick={toggleAutoScroll} style={{
                background: isAutoScrolling ? '#FFB6C1' : '#90EE90',
                color: isAutoScrolling ? '#000' : '#000',
                border: 'none',
                padding: '5px',
                borderRadius: '10px',
                cursor: 'pointer',
                gap: '10px'
            }}>
                {isAutoScrolling ?
                    <>
                        <i className="fa-regular fa-circle-pause"></i>
                        <span>スクロール再開</span>
                    </>
                    :
                    <>
                        <i className="fa-regular fa-circle-play"></i>
                        <span>スクロール停止</span>
                    </>
                }
            </button>
            <div className="PastServers">
                <div>
                    {['HQXA3iNK5J8', 'f0_ejK8O46U', '-P-gx3xLbOc', '1erfXcdEY5I', 'RqUMeA6Y-9M', '7THknyENXuE', 'MWAnnDVIFZc', 'a0ZvhMsXckw'].map((videoId) => (
                        <img
                            key={videoId}
                            src={getThumbnailUrl(videoId)}
                            alt="YouTube video thumbnail"
                            onClick={() => handleImageClick(videoId)}
                            loading="lazy"
                        />
                    ))}
                </div>
            </div>
            <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
                <iframe
                    src={`https://www.youtube-nocookie.com/embed/${selectedVideo}?si=kmnH5owFT74WlPK4&amp;controls=0`}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                    className='PastServersYoutubePreview'
                ></iframe>
                <p>周りの余白をクリックすることで閉じられます</p>
            </Modal>
        </div>
    );
}

export default PastServers;
