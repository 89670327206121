import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';

import Image1 from './images/2023-06-19_23.51.29 - toi Toi.png';
import Image2 from './images/2024-02-08_22.09.40 - アミノ酸.png';
import Image3 from './images/2024-05-19_18.38.44 - 紫音.png';
import Image4 from './images/2024-05-20_00.26.28 - 火ヤ矢サブ.png';
import Image5 from './images/2024-05-19_23.47.30 - すいか.png';
import Image6 from './images/IMG_20240520_015156 - 稲凪咲.png'
import Image7 from './images/2024-04-21_23.58.50 - たんチビ.png'

const Modal = ({ img, closeModal }) => {
  const stopPropagation = (e) => e.stopPropagation();

  return (
    <div className="modal show" onClick={closeModal}>
      <div className="modal-content" onClick={stopPropagation}>
        <span className="close" onClick={closeModal}>×</span>
        <img src={img.src} alt="画像" />
        <div className="modal-text">
          <p className='modal-title'>{img.title}</p>
          <div className="modal-cat-date">
            <p className='modal-category'><i class="fa-solid fa-tag"></i><span className='modal-category-span'>#{img.category}</span></p>
            <p className='modal-date'><i class="fa-solid fa-calendar-days"></i><span className="modal-date-span">{img.date}</span></p>
            <p className='modal-name'><i class="fa-solid fa-pencil"></i><span className="modal-name-span">{img.name}</span></p>
          </div>
          <div dangerouslySetInnerHTML={{ __html: img.text }} />
        </div>
      </div>
    </div>
  );
};

const useImageItems = () => {
  return [
    { src: Image1, title: "	拠点", category: "その他", date: "2023年6月~7月", name: "toitoi/ぱん", text: "<p>Hololoさんと作ってました。</p>" },
    { src: Image2, title: "車両基地", category: "建築", date: "2023年6月~7月", name: "H0ne0suke", text: "<p>気がついたらカップラーメンが出来てて、よく脱線したり、列車が荒ぶってた。<br />驚いたが、今やいい思い出？だと思う。</p>" },
    { src: Image3, title: "	観光のワンシーン", category: "日常", date: "2024年4月~5月", name: "紫音", text: "<p>今回の鯖で最大規模の街で撮った一枚です。</p>" },
    { src: Image4, title: "	花火大会", category: "風景", date: "2024年4月", name: "火ヤ矢", text: "<p>とても綺麗でした！</p>" },
    { src: Image5, title: "	打ち上げ花火、下から見ます(ｷﾘｯ 。", category: "風景", date: "2024年4月~5月", name: "すいか", text: "<p>ただただ、花火を眺められる場所があったので。</p>" },
    { src: Image6, title: "	5/19集合写真", category: "集合写真", date: "2024年4月~5月", name: "稲凪 咲", text: "<p>色々トラブルもありましたが、最後は皆で写真を撮れて、とても良い思い出です。</p>" },
    { src: Image7, title: "	お墓", category: "その他", date: "2024年4月負荷耐久テスト", name: "ちびたん。", text: "<p>一緒のお墓に入りたかったので推しと一緒にお墓に入りました(？)</p>" },
  ];
};

const CategoryButton = ({ category, handleCategorySelection, isSelected }) => {
  const buttonClass = isSelected ? 'SelectiveTagClass' : '';

  return (
    <button className={buttonClass} onClick={() => handleCategorySelection(category)}>
      #{category}
    </button>
  );
};

const ClearFilterButton = ({ clearFilters }) => {
  return (
    <button onClick={clearFilters}>
      カテゴリ解除
    </button>
  );
};

function Gallery() {
  const [modalImg, setModalImg] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const openModal = (img) => {
    setModalImg(img);
  };

  const closeModal = () => {
    setModalImg(null);
  };

  const imageItems = useImageItems();
  const filteredImages = imageItems.filter(item => selectedCategories.length === 0 || selectedCategories.includes(item.category));

  const handleCategorySelection = (category) => {
    if (selectedCategories.includes(category)) {
      setSelectedCategories(selectedCategories.filter(c => c !== category));
    } else {
      setSelectedCategories([...selectedCategories, category]);
    }
  };

  const clearFilters = () => {
    setSelectedCategories([]);
  };

  return (
    <div className='comp'>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "BreadcrumbList",
              "itemListElement": [
                {
                  "@type": "ListItem",
                  "position": 1,
                  "item": {
                    "@id": "https://woodryserver.pages.dev",
                    "name": "WoodryServer"
                  }
                },
                {
                  "@type": "ListItem",
                  "position": 2,
                  "item": {
                    "@id": "https://woodryserver.pages.dev/gallery",
                    "name": "ギャラリー"
                  }
                }
              ]
            }
          `}
        </script>
        <title>ギャラリー | WoodryServer</title>
        <meta name="description" content="サーバー内で撮られた画像を覗き見できます。Woodryさんが主催する参加型マルチサーバーのファンメイドサイトです。" />
      </Helmet>

      <section className='page-crumb'>
        <nav>
          <ol className="breadcrumb">
            <li><Link to="/">WoodryServer</Link></li>
            <li>ギャラリー</li>
          </ol>
        </nav>
      </section>

      <h1 className="sec-title"><i class="fa-solid fa-photo-film"></i>ギャラリー</h1>
      <br />
      {/* 画像分類タグ選択ボタン */}
      <div>
        <h3 className='category'><i class="fa-solid fa-tag"></i>画像タグを選択して検索する</h3>
      </div>
      <div className='category-buttons'>
        <CategoryButton category="自然" isSelected={selectedCategories.includes("自然")} handleCategorySelection={handleCategorySelection} />
        <CategoryButton category="建築" isSelected={selectedCategories.includes("建築")} handleCategorySelection={handleCategorySelection} />
        <CategoryButton category="集合写真" isSelected={selectedCategories.includes("集合写真")} handleCategorySelection={handleCategorySelection} />
        <CategoryButton category="日常" isSelected={selectedCategories.includes("日常")} handleCategorySelection={handleCategorySelection} />
        <CategoryButton category="探索" isSelected={selectedCategories.includes("探索")} handleCategorySelection={handleCategorySelection} />
        <CategoryButton category="風景" isSelected={selectedCategories.includes("風景")} handleCategorySelection={handleCategorySelection} />
        <CategoryButton category="その他" isSelected={selectedCategories.includes("その他")} handleCategorySelection={handleCategorySelection} />

      </div>
      <div className='category-delete-buttons'>
        <ClearFilterButton clearFilters={clearFilters} />
      </div>

      <div className='gallery-div'>
        {filteredImages.map((item, index) => (
          <div className='gallery-img' key={index} onClick={() => openModal(item)}>
            <img src={item.src} alt={`画像${index + 1}`} />
            <p className='gallery-title'>{item.title}</p>
            <p className='gallery-category'><i class="fa-solid fa-tag"></i>#{item.category}</p>
          </div>
        ))}
      </div>

      {modalImg && <Modal img={modalImg} closeModal={closeModal} />}
    </div>
  );
}

export default Gallery;
