import React from 'react';
import { Link } from 'react-router-dom';

import LatestArticle from '../LatestArticle';

function NewsContact() {
    return (
        <div className='newscontact' id='home-news'>
            <section className='section'>
                <div className="sec-div">
                    <div className='newscontact-news'>
                        <h1 className='sec-title'>ニュース</h1>
                        <div className='newscontact-news-supplement'>
                            <p>※最新のニュースが一つ表示されています。</p>
                        </div>
                        <LatestArticle />
                        <div className='newscontact-news-more'>
                            <Link to="/news/">もっと見る...</Link>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default NewsContact;
