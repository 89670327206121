import React from 'react';
import { Helmet } from "react-helmet";

import AnimationTrigger from './AnimationTrigger';
import PageTop from './HomePage/PageTop';
import About from './HomePage/About';
import Join from './HomePage/Join';
import News from './HomePage/News';
import Contact from './HomePage/Contact';
import Rule from './HomePage/Rule';
import LatestVideosPosts from './HomePage/LatestVideosPosts';

import PastServers from './HomePage/PastServers';

function HomePage() {
  return (
    <div className='comp'>

      <Helmet>
        <title>ホーム | WoodryServer</title>
        <meta name="description" content="Woodryさんが主催する参加型マルチサーバーのファンメイドサイトです。あなたも参加してみませんか？" />
      </Helmet>
      

      <AnimationTrigger>
        <PageTop />
      </AnimationTrigger>

      <AnimationTrigger>
        <About />
      </AnimationTrigger>

      <AnimationTrigger>
        <Join />
      </AnimationTrigger>

      <AnimationTrigger>
        <PastServers />
      </AnimationTrigger>

      <AnimationTrigger>
        <Rule />
      </AnimationTrigger>

      <AnimationTrigger>
        <Contact />
      </AnimationTrigger>

      <AnimationTrigger>
        <News />
      </AnimationTrigger>

      <AnimationTrigger>
        <LatestVideosPosts />
      </AnimationTrigger>

    </div >
  );
}

export default HomePage;
