import React from 'react';
import { Link } from 'react-router-dom';
import aboutIMG from '../../img/img/aboutIMG.png';
import NewTab_svg from '../../img/svg/arrow-up-right-from-square-solid.svg';

function About() {
    return (
        <div id='about'>{/* アバウト */}
            <section className='section'>
                <div className='sec-div'>
                    <h1 className="sec-title">About</h1>
                    <div className="about">
                        <div className="aboutIMG">
                            <img src={aboutIMG} alt="アバウト写真" loading="lazy" />
                        </div>
                        <ul>
                            <h2>WoodryServer</h2>
                            <li>Discordサーバーにて不定期で開催される視聴者参加型
                                <br />マルチサーバーです。
                            </li>
                            <li>あなたもぜひディスコードに参加してサーバーに
                                <br />参加してみませんか？
                            </li>
                            <li><a href="https://twitter.com/woodry_" target='_blarn'>
                                <img src={NewTab_svg} alt="新規タブSVG" className='newTab' />
                                WoodryさんのX(Twitter)はこちら
                            </a></li>
                            <div className="cont-but">
                                <Link to="/contact">お問い合わせ</Link>
                            </div>
                        </ul>
                    </div>
                </div >
            </section >
        </div >
    )
}

export default About