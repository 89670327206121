import React from 'react';
import TwitterTimeline from './Twitter';
import NewYouTube from './NewYouTube';

function LatestVideosPosts() {
    return (
        <div id='home-sns'>
            <h1 className='sec-title'>最新動画/ポスト</h1>
            <p className='text-align'>仕様変更により最新ポストが見れません。イーロンさん頼むから直して</p>
            <div className="LatestVideosPosts">
                <NewYouTube />
                <TwitterTimeline />
            </div>
        </div>
    )
}

export default LatestVideosPosts