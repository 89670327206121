import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Header_svg from '../img/svg/WoodryServer_KawaiiLogo.svg';
import KawaiiLogo from '../img/svg/WoodryServer_KawaiiLogo.svg';
import '../css/hunb.css';

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const isKawaii = location.search.includes('?kawaii=true');

  const getLogoClass = (logo) => {
    if (logo === KawaiiLogo) {
      return 'kawaii-header-logo';
    } else {
      return 'default-header-logo';
    }
  };

  // メニュー項目がクリックされたときの処理
  const handleMenuClick = () => {
    setIsOpen(false); // メニューを閉じる
  };

  return (
    <div>
      <div className="menu-sp">
        <div className={`header ${isOpen ? 'open' : ''}`}>
          <img src={isKawaii ? KawaiiLogo : Header_svg} alt="サイトロゴ" className={getLogoClass(isKawaii ? KawaiiLogo : Header_svg)} />

          <button className="hamburger-button" onClick={toggleMenu}>
            <span className="hamburger-icon"></span>
          </button>

          <nav className="menu">
            <ul>
              {/* リンクがクリックされたときに handleMenuClick を呼び出す */}
              <li><Link to="/" onClick={handleMenuClick}>ホーム</Link></li>
              <li><Link to="/gallery" onClick={handleMenuClick}>ギャラリー</Link></li>
              <li><Link to="/news" onClick={handleMenuClick}>ニュース</Link></li>
              <li><Link to="/rule" onClick={handleMenuClick}>サイトポリシー</Link></li>
              <li><Link to="/contact" onClick={handleMenuClick}>お問い合わせ</Link></li>
            </ul>
          </nav>
        </div>
      </div>

      <div className="header menu-pc">
        <img src={isKawaii ? KawaiiLogo : Header_svg} alt="サイトロゴ" className={getLogoClass(isKawaii ? KawaiiLogo : Header_svg)} />
        <nav>
          <ul>
            {/* リンクがクリックされたときに handleMenuClick を呼び出す */}
            <li><Link to="/" onClick={handleMenuClick}>ホーム</Link></li>
            <li><Link to="/gallery" onClick={handleMenuClick}>ギャラリー</Link></li>
            <li><Link to="/news" onClick={handleMenuClick}>ニュース</Link></li>
            <li><Link to="/rule" onClick={handleMenuClick}>サイトポリシー</Link></li>
            <li><Link to="/contact" onClick={handleMenuClick}>お問い合わせ</Link></li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Header;
