import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

function formatDate(isoDateString) {
    const date = new Date(isoDateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // 月は0-indexedなので+1する
    const day = date.getDate();
    return `${year}年${month}月${day}日`;
}

function extractFirstLine(content) {
    const lines = content.split('\n');
    return lines[0];
}

function shortenContent(content, maxLength) {
    if (content.length > maxLength) {
        return content.substring(0, maxLength) + ' ...';
    }
    return content;
}

function MicroCMS({ onLoadingComplete }) {
    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const result = await axios.get(process.env.REACT_APP_API_ENDPOINT, {
                headers: { 'X-API-KEY': process.env.REACT_APP_API_KEY }
            });

            // 日付をフォーマットし、最初の一行を抽出する
            const formattedData = result.data.contents.map(article => ({
                ...article,
                date: formatDate(article.date),
                firstLine: extractFirstLine(article.content),
                shortenedContent: shortenContent(article.content, 50),
                thumbnailUrl: article.thumbnail ? article.thumbnail.url : null // 50文字以上の場合に短縮する
            }));

            setData(formattedData);
            onLoadingComplete();
        };

        fetchData();
    }, [onLoadingComplete]); // onLoadingCompleteを依存配列に含める

    return (
        <div className="App news-sec">
            {data && data.map((article, index) => (
                <div key={index} className="news">
                    {article.thumbnailUrl && <img src={article.thumbnailUrl} alt="記事サムネイル" />}
                    <div className="news-text">
                        <h1>{article.title}</h1>
                        <p className='news-date'>{article.date}</p>
                        <div className='news-content' dangerouslySetInnerHTML={{ __html: article.shortenedContent }} />
                        <div className='news-ReadMore news-look'>
                            <Link to={`/article/${article.id}`}>続きを読む→</Link>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default MicroCMS;
