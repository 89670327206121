import React, { useEffect } from 'react';

function Twitter() {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://platform.twitter.com/widgets.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div>
            <a class="twitter-timeline" data-width="560" data-height="450" href="https://twitter.com/woodry_?ref_src=twsrc%5Etfw">Tweets by woodry_</a>
        </div>
    );
}

export default Twitter;
