import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import AnimationTrigger from './AnimationTrigger';
import MicroCMS from './microCMS-News';
import cloud_svg from '../img/svg/cloud.svg';

import SearchBar from './Parts/SearchBar';

function News() {
  const [isLoading, setIsLoading] = useState(true); // 初期状態をtrueに設定

  // onLoadingComplete関数をuseCallbackでラップ
  const onLoadingComplete = useCallback(() => {
    setIsLoading(false); // データの読み込みが完了したら、isLoadingをfalseに設定
  }, []); // 依存配列が空なので、この関数はコンポーネントのライフサイクル中に一度だけ作成されます

  return (
    <div className='comp'>
      <Helmet>
        <script type="application/ld+json">
          {`
              {
                "@context": "http://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "item": {
                      "@id": "https://woodryserver.pages.dev",
                      "name": "WoodryServer"
                    }
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "item": {
                      "@id": "https://woodryserver.pages.dev/news",
                      "name": "ニュース"
                    }
                  }
                ]
              }
            `}
        </script>
        <title>ニュース | WoodryServer</title>
        <meta name="description" content="Woodryさんの主催する視聴者参加型マルチサーバーのファンメイドサイトです。このページはニュースが表示されます。" />
      </Helmet>

      {/* パンくずリスト */}
      <section className='page-crumb'>
        <nav>
          <ol className="breadcrumb">
            <li><Link to="/">WoodryServer</Link></li>
            <li>ニュース</li>
          </ol>
        </nav>
      </section>


      {/* サイト内検索 */}
      <SearchBar />

      {/* ニュースローディング */}
      <AnimationTrigger>
        {isLoading && (
          <div id="NewsLoading" >
            <img src={cloud_svg} alt="雲ローディング" />
            <div>
              <p>Now Loading...</p>
              <p>記事テータを取得しています</p>
            </div>
          </div>
        )}
      </AnimationTrigger>


      {/* CMSから取得したニュース */}
      <AnimationTrigger AnimationTrigger >
        <MicroCMS onLoadingComplete={onLoadingComplete} />
      </AnimationTrigger >

    </div >
  )
}

export default News;
