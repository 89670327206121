import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import './App.css';
import './css/news.css';
import './css/footer.css';
import './css/rule.css';
import './css/newsContact.css';
import './css/LatestVideosPosts.css';
import './css/Err.css';
import './css/NewsText.css';
import './css/PastServers.css';
import './css/LoadingScreen.css';
import './css/loose-leaf.css';
import './css/SearchBar.css';

import './css/Gallery.css';

import LoadingScreen from './components/LoadingScreen';

import ScrollToTop from './components/ScrollToTop';
import HomePage from './components/HomePage';
import Header from './components/Header';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Rule from './components/Rule';
import News from './components/News';

import ArticleDetail from './components/NewNews/ArticleDetail';

import E404 from './components/err/E404';
import Gallery from './components/Gallery/Gallery';
import AmpAd from './components/AmpAd';

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 4500); // 4.5秒後にローディングを完了させる
  }, []);

  const handlePageLoad = (isPageLoaded) => {
    if (isPageLoaded) {
      setTimeout(() => {
        setIsLoading(false);
      }, 4500);
    }
  };

  return (
    <Router>
      <div className="App">
        {isLoading ? (
          <LoadingScreen onPageLoad={handlePageLoad} />
        ) : (
          <>
            <Header />
            <ScrollToTop />
            <Routes>
              <Route path='/' element={<HomePage />} />
              <Route path='/news' element={<News />} ></Route>
              <Route path='/rule' element={<Rule />} />
              <Route path='/contact' element={<Contact />} />
              <Route path='/gallery' element={<Gallery />} />

              <Route path="/article/:id" element={<ArticleDetail />} />

              <Route path='*' element={<E404 />}></Route>

            </Routes>

            {/* ディスプレイ広告 */}
            <div>
              <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1638013286657091"
                crossorigin="anonymous"></script>
              <ins class="adsbygoogle"
                style={{ display: "block", }}
                data-ad-client="ca-pub-1638013286657091"
                data-ad-slot="8081768718"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
              <script>
                (adsbygoogle = window.adsbygoogle || []).push({ });
              </script>
            </div>

            <Footer />

            <AmpAd />
          </>
        )}
      </div>
    </Router>
  );
}



export default App;
